import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import config from '../../../../../shared/utils/config.erb';
import { runShape, runStatisticShape } from '../../../shapes/entities';

import EvaluationLink from './evaluation_link';

import mascot1 from '../../../../../../assets/images/mascot_card/level_1.png';
import mascot2 from '../../../../../../assets/images/mascot_card/level_2.png';
import mascot3 from '../../../../../../assets/images/mascot_card/level_3.png';
import mascot4 from '../../../../../../assets/images/mascot_card/level_4.png';
import mascot5 from '../../../../../../assets/images/mascot_card/level_5.png';
import mascot6 from '../../../../../../assets/images/mascot_card/level_6.png';
import mascot7 from '../../../../../../assets/images/mascot_card/level_7.png';
import mascot8 from '../../../../../../assets/images/mascot_card/level_8.png';
import mascot9 from '../../../../../../assets/images/mascot_card/level_9.png';
import mascot10 from '../../../../../../assets/images/mascot_card/level_10.png';

const mascotImages = {
  1: mascot1,
  2: mascot2,
  3: mascot3,
  4: mascot4,
  5: mascot5,
  6: mascot6,
  7: mascot7,
  8: mascot8,
  9: mascot9,
  10: mascot10
};

const getUserScoreLevel = ({
  user_score: userScore,
  achievable_score: achievableScore
}) => {
  const levelStep = achievableScore / 10;

  if (userScore <= levelStep) return 1;
  if (userScore <= levelStep * 2) return 2;
  if (userScore <= levelStep * 3) return 3;
  if (userScore <= levelStep * 4) return 4;
  if (userScore <= levelStep * 5) return 5;
  if (userScore <= levelStep * 6) return 6;
  if (userScore <= levelStep * 7) return 7;
  if (userScore <= levelStep * 8) return 8;
  if (userScore <= levelStep * 9) return 9;

  return 10;
};

const MascotCard = ({ run, showTitle, runStatistic }) => {
  const userScoreLevel = getUserScoreLevel(runStatistic.attributes);

  const mascotClass = classNames('t-text-align--center', {
    'c-results-card__no-header': !showTitle
  });

  return (
    <article className="l-grid__item c-results-card qa-mascot-card">
      <div className="l-flex-grid__intro l-flex-grid__intro-ranking">
        {showTitle && (
          <header>
            <FormattedMessage id="runs.results.mascot_header">
              {text => <h2 className="o-h2 t-text-weight--bold">{text}</h2>}
            </FormattedMessage>
          </header>
        )}
        <div className={mascotClass}>
          <img
            src={mascotImages[userScoreLevel]}
            alt={config.mascotAlt}
            className="c-results-card__mascot"
          />
        </div>
        <div className="t-padding--top">
          <p className="t-line-height--tight t-text-align--center t-no-margin">
            <FormattedMessage
              id={'runs.results.mascot_quotes.level_'.concat(userScoreLevel)}
            />
          </p>
        </div>
      </div>
      <div className="l-flex-grid__end">
        <EvaluationLink run={run} />
      </div>
    </article>
  );
};

MascotCard.propTypes = {
  runStatistic: runStatisticShape.isRequired,
  showTitle: PropTypes.bool,
  run: runShape.isRequired
};

MascotCard.defaultProps = {
  showTitle: true
};

export default MascotCard;
