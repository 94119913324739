import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import menuSVG from '../../../assets/images/menu.svg';

const MobileMenuButton = ({ onClick }) => (
  <button
    type="button"
    onClick={onClick}
    className="o-hidden@medium o-button o-button--icon o-button--topbar"
  >
    <SVG
      src={menuSVG}
      alt=""
      className="o-icon o-button__icon"
      width="30"
      height="30"
      role="presentation"
    />
    <span className="o-visuallyhidden">Toggle navigation</span>
  </button>
);

MobileMenuButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default MobileMenuButton;
