import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import enhanceWithClickOutside from 'react-click-outside';

import courseShape from './shapes/course';
import pathsShape from './shapes/paths';
import userShape from './shapes/user';
import internationalize from '../../lib/internationalize';

import MobileMenuButton from './mobile_menu_button';
import AccountMenuItem from './account_menu';
import CoursesMenuItem from './courses_menu';
import DashboardMenuItem from './dashboard_menu';

export class UserMenu extends Component {
  constructor(props) {
    super(props);

    this.state = { showMobileMenu: false, scrollY: window.scrollY };
    this.toggle = this.toggle.bind(this);
    this.onOpen = this.onOpen.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onViewportChange = this.onViewportChange.bind(this);

    const mqList = window.matchMedia('screen and (min-width: 45em)');
    mqList.addListener(this.onViewportChange);
  }

  onOpen() {
    const { scrollY } = window;
    this.setState({ scrollY });
    document.body.style.position = 'fixed';
    document.body.style.top = `-${scrollY}px`;
  }

  onClose() {
    const { scrollY } = this.state;
    document.body.style.position = '';
    document.body.style.top = '';
    window.scrollTo(0, scrollY);
  }

  onViewportChange(event) {
    const { showMobileMenu } = this.state;

    if (event.matches && showMobileMenu) {
      this.toggle();
    }
  }

  handleClickOutside() {
    this.setState({ showMobileMenu: false });
  }

  toggle() {
    const { showMobileMenu } = this.state;
    if (showMobileMenu) {
      this.onClose();
    } else {
      this.onOpen();
    }
    this.setState({ showMobileMenu: !showMobileMenu });
  }

  render() {
    const {
      user,
      paths,
      courses,
      highlightCompendiumLink,
      showCompendium,
      showPerformance
    } = this.props;
    const hasCourses = courses.length > 0;
    const { showMobileMenu } = this.state;

    const className = classNames('c-nav o-list-reset', {
      'c-nav--active': showMobileMenu
    });

    return (
      <>
        <MobileMenuButton onClick={this.toggle} />
        <ul className={className}>
          {user && hasCourses && (
            <li className="t-margin--right@medium">
              <CoursesMenuItem courses={courses} />
            </li>
          )}
          {user && showCompendium && (
            <li className="o-hidden@medium">
              <DashboardMenuItem
                paths={paths}
                highlightCompendiumLink={highlightCompendiumLink}
              />
            </li>
          )}
          {user && (
            <li>
              <AccountMenuItem
                user={user}
                paths={paths}
                showPerformance={showPerformance}
              />
            </li>
          )}
        </ul>
      </>
    );
  }
}

UserMenu.defaultProps = {
  user: null,
  courses: [],
  showCompendium: true,
  showPerformance: true,
  highlightCompendiumLink: false
};

UserMenu.propTypes = {
  paths: pathsShape.isRequired, // eslint-disable-line react/no-typos
  user: userShape,
  courses: PropTypes.arrayOf(courseShape),
  showCompendium: PropTypes.bool,
  showPerformance: PropTypes.bool,
  highlightCompendiumLink: PropTypes.bool
};

export default internationalize(enhanceWithClickOutside(UserMenu));
