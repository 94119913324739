import ReactOnRails from 'react-on-rails';

import InlineSVG from 'react-inlinesvg';
import FontFaceObserver from 'fontfaceobserver/fontfaceobserver';

import RunPlayer from './run_player/main';
import RunCountdown from './run_countdown/index';
import SpiderGraphic from './spider_graphic';
import BarChart from './bar_chart';
import IntlUserMenu from './user_menu';
import ModalVideoPlayer from './modal_video_player';
import InfoPagePreview from '../shared/components/info_page_preview';
import RichContentViewer from '../shared/components/rich/viewer';
import QuestionBlockPreview from '../shared/components/question_block_preview';
import IntlQuestionPreview from '../admin/components/question_preview';
import TestProgressBar from './test_progress_bar';

import './pagy.js.erb';

import './index.scss';

ReactOnRails.register({
  InlineSVG,
  RunPlayer,
  RunCountdown,
  SpiderGraphic,
  BarChart,
  IntlUserMenu,
  ModalVideoPlayer,
  InfoPagePreview,
  RichContentViewer,
  QuestionBlockPreview,
  IntlQuestionPreview,
  TestProgressBar
});

const proximaNovaRegular = new FontFaceObserver('ProximaNova', { weight: 400 });
const proximaNovaBold = new FontFaceObserver('ProximaNova', { weight: 700 });
const proximaNovaRegularIt = new FontFaceObserver('ProximaNova', {
  weight: 400,
  style: 'italic'
});
const euclidFlexRegular = new FontFaceObserver('EuclidFlex', { weight: 400 });

Promise.all([
  proximaNovaRegular.load('DÜ', 10000),
  proximaNovaBold.load('DÜ', 10000),
  proximaNovaRegularIt.load('DÜ', 10000),
  euclidFlexRegular.load('DÜ', 10000)
]).then(
  () => {
    document.documentElement.classList.add('fonts-loaded');
  },
  () => {
    document.documentElement.classList.remove('fonts-loaded');
  }
);
