import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import pathsShape from '../shapes/paths';

const DashboardMenuItem = ({ paths, highlightCompendiumLink }) => {
  const classnameBase =
    'o-nav-link o-nav-link--menu o-truncate-with-ellipsis t-font-family--body t-no-margin c-dropdown__item';
  const classnameHighlighted =
    'o-nav-link o-nav-link--menu is-highlighted o-truncate-with-ellipsis t-font-family--body t-no-margin c-dropdown__item';

  return (
    <ul className="o-list-reset c-nav__section">
      <li>
        <a
          href={paths.eLearning}
          className={
            highlightCompendiumLink ? classnameBase : classnameHighlighted
          }
        >
          <FormattedMessage id="shared.user_account_menu.e_learning" />
        </a>
      </li>
      <li>
        <a
          href={paths.compendium}
          className={
            highlightCompendiumLink ? classnameHighlighted : classnameBase
          }
        >
          <FormattedMessage id="shared.user_account_menu.compendium" />
        </a>
      </li>
    </ul>
  );
};

DashboardMenuItem.propTypes = {
  paths: pathsShape.isRequired,
  highlightCompendiumLink: PropTypes.bool.isRequired
};

export default DashboardMenuItem;
